import React, { KeyboardEvent, useEffect, useState } from "react";
import { Button, Header, Icon, Input, List, Modal } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI ANE
import CardSetupAnesthesiaTemplateUX from "./CardSetupAnesthesiaTemplateUX";
import CardSetupAnesthesiaTemplateDrugUX from "./CardSetupAnesthesiaTemplateDrugUX";
import CardSetupAnesthesiaTemplateSupplyUX from "./CardSetupAnesthesiaTemplateSupplyUX";
import CardSetupAnesthesiaTemplateTreatmentUX from "./CardSetupAnesthesiaTemplateTreatmentUX";
import DrugSearchBox from "../TPD/DrugSearchBox";
import { useIntl } from "react-intl";
import { RowInfo } from "react-table-6";

const CARD_KEY: string = "CardAnesTemplate";

type CardSetupAnesthesiaTemplateProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  masterOptions?: any;
  drugSearchResult?: any;
  drugSearchLoading?: boolean;
  drugSearchText?: string;

  // seq
  runSequence?: any;
  AnesTemplateSequence?: any;
};

const CardSetupAnesthesiaTemplate = (props: CardSetupAnesthesiaTemplateProps) => {
  const intl = useIntl();
  const [tab, setTab] = useState<string>("Drug");
  const [searchTemplateName, setSearchTemplateName] = useState<string>("");
  const [modSetTemplateName, setModSetTemplateName] = useState<any>({
    open: false,
    isCreate: true,
    name: "",
  });
  const [openModDeleteTemplate, setOpenModDeleteTemplate] = useState<boolean>(false);
  const [modSetDrug, setModSetDrug] = useState<any>({
    open: false,
    isCreate: true,
    showSearchResult: false,
    data: {},
  });
  const [modDeleteDrug, setModDeleteDrug] = useState<any>({
    open: false,
    item: null,
  });
  const [modSetSupply, setModSetSupply] = useState<any>({
    open: false,
    isCreate: true,
    showSearchResult: false,
    data: {},
  });
  const [modDeleteSupply, setModDeleteSupply] = useState<any>({
    open: false,
    item: null,
  });
  const [modSetTreatment, setModSetTreatment] = useState<any>({
    open: false,
    isCreate: true,
    showSearchResult: false,
    showSearchICD9: false,
    data: {},
    dataICD9: {},
  });
  const [modDeleteTreatment, setModDeleteTreatment] = useState<any>({
    open: false,
    item: null,
  });

  useEffect(() => {
    props.runSequence({
      sequence: "AnesTemplate",
      action: "LIST_TEMPLATE",
      cardKey: CARD_KEY,
    });
  }, []);

  useEffect(() => {
    if (props.buttonLoadCheck?.[`${CARD_KEY}_SAVE_DRUG_ANS_ITEM`] === "SUCCESS") {
      handleCloseModSetDrug();
    } else if (props.buttonLoadCheck?.[`${CARD_KEY}_SAVE_SUPPLY_ANS_ITEM`] === "SUCCESS") {
      handleCloseModSetSupply();
    } else if (props.buttonLoadCheck?.[`${CARD_KEY}_SAVE_TREATMENT_ANS_ITEM`] === "SUCCESS") {
      handleCloseModSetTreatment();
    }
  }, [props.buttonLoadCheck]);

  useEffect(() => {
    if (props.AnesTemplateSequence?.supplyList?.length > 0) {
      setModSetSupply({ ...modSetSupply, showSearchResult: true });
    }
  }, [props.AnesTemplateSequence?.supplyList]);

  useEffect(() => {
    if (props.AnesTemplateSequence?.treatmentList?.length > 0) {
      setModSetTreatment({
        ...modSetTreatment,
        showSearchResult: true,
        showSearchICD9: false,
      });
    }
  }, [props.AnesTemplateSequence?.treatmentList]);

  useEffect(() => {
    if (props.AnesTemplateSequence?.icd9List?.length > 0) {
      setModSetTreatment({
        ...modSetTreatment,
        showSearchResult: false,
        showSearchICD9: true,
      });
    }
  }, [props.AnesTemplateSequence?.icd9List]);

  useEffect(() => {
    let drugUnit = props.AnesTemplateSequence?.drugUnit;
    setModSetDrug({
      ...modSetDrug,
      data: {
        ...modSetDrug.data,
        unit: drugUnit?.unit,
        route: drugUnit?.route,
        site: drugUnit?.site,
        frequency: drugUnit?.frequency,
        method: drugUnit?.method,
      },
    });
  }, [props.AnesTemplateSequence?.drugUnit]);
  // tab
  const handleChangeTab = (_event: any, data: any) => {
    setTab(data.name);
  };

  // template
  const handleChangeSearchTemplateName = (_event: any, data: any) => {
    setSearchTemplateName(data.value);
  };

  const handleSearchTemplateName = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.runSequence({
        sequence: "AnesTemplate",
        action: "LIST_TEMPLATE",
        cardKey: CARD_KEY,
        name: searchTemplateName,
      });
    }
  };

  const handleTemplate = () => {
    if (modSetTemplateName.isCreate) {
      props.runSequence({
        sequence: "AnesTemplate",
        action: "CREATE_TEMPLATE",
        cardKey: CARD_KEY,
        templateName: modSetTemplateName.name,
      });
    } else if (!modSetTemplateName.isCreate && props.AnesTemplateSequence?.templateSelected?.id) {
      props.runSequence({
        sequence: "AnesTemplate",
        action: "CHANGE_TEMPLATE_NAME",
        cardKey: CARD_KEY,
        templateName: modSetTemplateName.name,
      });
    }
    handleCloseModSetTemplateName();
  };

  const handleDeleteTemplate = () => {
    props.runSequence({
      sequence: "AnesTemplate",
      action: "DELETE_TEMPLATE",
      cardKey: CARD_KEY,
    });
    handleCloseModDeleteTemplate();
    props.setProp(
      "AnesTemplateSequence.templateSelected",
      props.AnesTemplateSequence?.templateList?.items?.[0]
    );
  };

  const handleCloseModSetTemplateName = () => {
    setModSetTemplateName({ open: false, isCreate: true, name: "" });
  };

  const handleCloseModDeleteTemplate = () => {
    setOpenModDeleteTemplate(false);
  };

  // table
  const columnTemplate: any[] = [
    { Header: "ชื่อรายการ", accessor: "name", minWidth: 100 },
    {
      Header: "",
      accessor: "action",
      width: 100,
      Cell: (row: any) => (
        <>
          <Button
            icon
            color="green"
            size="mini"
            onClick={() => {
              if (tab === "Supply") {
                setModSetSupply({
                  open: true,
                  isCreate: false,
                  showSearchResult: false,
                  data: {
                    ...row.original,
                    supplySearch: "",
                    supplySelected: {
                      id: row.original?.supply,
                      name: row.original?.name || "",
                    },
                    eligibilityType: row.original?.eligibility_type,
                  },
                });
              } else if (tab === "Operative") {
                setModSetTreatment({
                  open: true,
                  isCreate: false,
                  showSearchResult: false,
                  data: {
                    ...row.original,
                    treatmentSearch: "",
                    treatmentSelected: {
                      id: row.original?.treatment,
                      name_code: row.original?.name || "",
                    },
                    performer: row.original?.to_be_performer,
                    orderDetail: row.original?.order_detail,
                    equipmentDetail: row.original?.equipment_detail,
                    eligibilityType: row.original?.eligibility_type,
                  },
                  dataICD9: {
                    treatmentSelected: {
                      icdterm: row.original?.icd9cm_term,
                      icd10_id: row.original?.icd9cm,
                      medterm_id: row.original?.icd9cm_med_term,
                    },
                  },
                });
              } else {
                props.setProp("drugSearchText", row.original?.product_full_name || "");

                setModSetDrug({
                  open: true,
                  isCreate: false,
                  showSearchResult: false,
                  data: {
                    ...row.original,
                    drugSearch: "",
                    drugSelected: {
                      id: row.original?.drug,
                      full_name: row.original?.product_full_name || "",
                    },
                  },
                });
              }
            }}
          >
            <Icon name="edit outline" />
          </Button>
          <Button
            icon
            color="red"
            size="mini"
            onClick={() => {
              if (tab === "Supply") {
                setModDeleteSupply({ open: true, item: row.original });
              } else if (tab === "Operative") {
                setModDeleteTreatment({ open: true, item: row.original });
              } else {
                setModDeleteDrug({ open: true, item: row.original });
              }
            }}
          >
            <Icon name="minus" />
          </Button>
        </>
      ),
    },
  ];

  // DrugANSItem
  const handleChangeDrugANS = (_event: any, data: any) => {
    let result = { ...modSetDrug.data, [data.name]: data.value };
    setModSetDrug({ ...modSetDrug, data: result });
  };

  const handleDeleteDrug = () => {
    props.runSequence({
      sequence: "AnesTemplate",
      action: "DELETE_DRUG_ANS_ITEM",
      cardKey: CARD_KEY,
      drug: modDeleteDrug.item,
    });
    handleCloseModDeleteDrug();
  };

  const handleCloseModSetDrug = () => {
    setModSetDrug({
      open: false,
      isCreate: true,
      showSearchResult: false,
      data: {},
    });
  };

  const handleCloseModDeleteDrug = () => {
    setModDeleteDrug({ open: false, item: null });
  };

  // SupplyANSItem
  const handleChangeSupplyANS = (_event: any, data: any) => {
    let result = { ...modSetSupply.data, [data.name]: data.value };
    setModSetSupply({ ...modSetSupply, data: result });
  };

  const handleDeleteSupply = () => {
    props.runSequence({
      sequence: "AnesTemplate",
      action: "DELETE_SUPPLY_ANS_ITEM",
      cardKey: CARD_KEY,
      supply: modDeleteSupply.item,
    });
    handleCloseModDeleteSupply();
  };

  const handleCloseModSetSupply = () => {
    setModSetSupply({
      open: false,
      isCreate: true,
      showSearchResult: false,
      data: {},
    });
  };

  const handleCloseModDeleteSupply = () => {
    setModDeleteSupply({ open: false, item: null });
  };

  // OperatingTreatmentANSItem
  const handleChangeTreatmentANS = (_event: any, data: any) => {
    let result = { ...modSetTreatment.data, [data.name]: data.value };
    setModSetTreatment({ ...modSetTreatment, data: result });
  };

  const handleDeleteTreatment = () => {
    props.runSequence({
      sequence: "AnesTemplate",
      action: "DELETE_TREATMENT_ANS_ITEM",
      cardKey: CARD_KEY,
      treatment: modDeleteTreatment.item,
    });
    handleCloseModDeleteTreatment();
  };

  const handleCloseModSetTreatment = () => {
    setModSetTreatment({
      open: false,
      isCreate: true,
      showSearchResult: false,
      data: {},
    });
  };

  const handleCloseModDeleteTreatment = () => {
    setModDeleteTreatment({ open: false, isCreate: true });
  };

  const handleSearchChange = () => {
    const result = {
      ...modSetDrug.data,
      drugSelected: null,
    };

    setModSetDrug({ ...modSetDrug, data: result });
  };

  const handleSelectDrug = (rowInfo: RowInfo) => {
    const drug = props.drugSearchResult[rowInfo.index];

    const result = {
      ...modSetDrug.data,
      drugSelected: drug,
    };

    setModSetDrug({
      ...modSetDrug,
      data: result,
      showSearchResult: false,
    });

    props.setProp("drugSearchText", drug.full_name);

    props.runSequence({
      sequence: "AnesTemplate",
      action: "GET_DERUG_UNIT",
      id: result?.drugSelected?.id,
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        languageUX={props.languageUX}
      />

      <CardSetupAnesthesiaTemplateUX
        // function
        onChangeTab={handleChangeTab}
        onChangeSearchTemplateName={handleChangeSearchTemplateName}
        onSearchTemplateName={handleSearchTemplateName}
        // data
        tab={tab}
        searchTemplateName={searchTemplateName}
        // table
        columnTemplate={columnTemplate}
        templateData={
          tab === "Supply"
            ? props.AnesTemplateSequence?.templateSelected?.supplys
            : tab === "Operative"
            ? props.AnesTemplateSequence?.templateSelected?.or_treatments
            : props.AnesTemplateSequence?.templateSelected?.drugs
        }
        // component
        listTemplateName={
          <List selection celled verticalAlign="middle">
            {props.AnesTemplateSequence?.templateList?.items?.map((item: any, index: number) => {
              return (
                <List.Item
                  key={index}
                  style={{
                    background:
                      props.AnesTemplateSequence?.templateSelected?.id === item.id
                        ? "lightgrey"
                        : "",
                    borderTop: index === 0 ? "none" : "",
                  }}
                  onClick={() => props.setProp("AnesTemplateSequence.templateSelected", item)}
                >
                  <List.Content floated="left">
                    <List.Header>{item.name}</List.Header>
                  </List.Content>
                  <List.Content floated="right">
                    <Button
                      icon
                      color="green"
                      size="mini"
                      onClick={() => {
                        setModSetTemplateName({
                          open: true,
                          isCreate: false,
                          name: item.name,
                        });
                        props.setProp("AnesTemplateSequence.templateSelected", item);
                      }}
                    >
                      <Icon name="edit outline" />
                    </Button>
                    <Button
                      icon
                      color="red"
                      size="mini"
                      onClick={() => {
                        setOpenModDeleteTemplate(true);
                        props.setProp("AnesTemplateSequence.templateSelected", item);
                      }}
                    >
                      <Icon name="minus" />
                    </Button>
                  </List.Content>
                </List.Item>
              );
            })}
          </List>
        }
        buttonCreateTemplate={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              setModSetTemplateName({ open: true, isCreate: true });
            }}
            // data
            paramKey={`${CARD_KEY}_CREATE_TEMPLATE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_CREATE_TEMPLATE`]}
            // config
            color="green"
            size="medium"
            fluid={true}
            title={intl.formatMessage({ id: "สร้างชุดค่าใชัจ่ายใหม่" })}
          />
        }
        buttonCreateItem={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              if (tab === "Supply") {
                setModSetSupply({
                  open: true,
                  isCreate: true,
                  showSearchResult: false,
                  data: {},
                });
              } else if (tab === "Operative") {
                setModSetTreatment({ open: true, isCreate: true });
              } else {
                setModSetDrug({
                  open: true,
                  isCreate: true,
                  showSearchResult: false,
                  data: {},
                });
              }
            }}
            // data
            paramKey={`${CARD_KEY}_CREATE_ITEM}`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_CREATE_ITEM`]}
            // config
            color="green"
            disabled={!props.AnesTemplateSequence?.templateSelected?.id}
            size="medium"
            fluid={true}
            title={intl.formatMessage({ id: "เพิ่มรายการค่าใช้จ่ายใหม่" })}
          />
        }
        languageUX={props.languageUX}
      />

      {/* Set Template Name */}
      <Modal
        closeIcon
        size="tiny"
        open={modSetTemplateName.open}
        onClose={handleCloseModSetTemplateName}
      >
        <Header content={intl.formatMessage({ id: "ตั้งชื่อ Template" })} />
        <Modal.Content>
          <Input
            focus
            fluid
            placeholder={intl.formatMessage({ id: "ชื่อ Template" })}
            value={modSetTemplateName.name}
            onChange={(_event: any, data: any) => {
              setModSetTemplateName({
                ...modSetTemplateName,
                name: data.value,
              });
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleTemplate}>
            <Icon name="checkmark" /> บันทึก
          </Button>
          <Button color="red" onClick={handleCloseModSetTemplateName}>
            <Icon name="remove" /> ยกเลิก
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Setup Drug */}
      <Modal
        closeIcon
        size="large"
        open={modSetDrug.open && !!props.AnesTemplateSequence?.templateSelected?.id}
        onClose={handleCloseModSetDrug}
      >
        <CardSetupAnesthesiaTemplateDrugUX
          // function
          onChangeDrugANS={handleChangeDrugANS}
          // options
          masterOptions={props.masterOptions}
          // data
          template={props.AnesTemplateSequence?.templateSelected}
          data={modSetDrug.data}
          onCancelEdit={handleCloseModSetDrug}
          drugSearchBox={
            <DrugSearchBox
              onEvent={props.onEvent}
              setProp={props.setProp}
              drugSearchLoading={props.drugSearchLoading}
              drugSearchResult={props.drugSearchResult}
              drugSearchText={props.drugSearchText}
              selectedDrug={modSetDrug.data?.drugSelected || null}
              selectOnBlur={false}
              onSearchChange={handleSearchChange}
              onSelect={handleSelectDrug}
            />
          }
          // component
          buttonCreateDrug={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "AnesTemplate",
                  action: "SAVE_DRUG_ANS_ITEM",
                  cardKey: CARD_KEY,
                  data: {
                    ...modSetDrug.data,
                    drug: modSetDrug.data?.drugSelected?.id,
                  },
                });
              }}
              // data
              paramKey={`${CARD_KEY}_SAVE_DRUG_ANS_ITEM`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_SAVE_DRUG_ANS_ITEM`]}
              // config
              color="green"
              size="medium"
              fluid={true}
              title={intl.formatMessage({ id: "บันทึก" })}
            />
          }
          languageUX={props.languageUX}
        />
      </Modal>

      {/* Setup Supply */}
      <Modal
        closeIcon
        size="large"
        open={modSetSupply.open && !!props.AnesTemplateSequence?.templateSelected?.id}
        onClose={handleCloseModSetSupply}
      >
        <CardSetupAnesthesiaTemplateSupplyUX
          // function
          onChangeSupplyANS={handleChangeSupplyANS}
          // options
          masterOptions={props.masterOptions}
          // data
          template={props.AnesTemplateSequence?.templateSelected}
          data={modSetSupply.data}
          onCancelEdit={handleCloseModSetSupply}
          // table
          showSearchResult={modSetSupply.showSearchResult}
          supplySearchResult={props.AnesTemplateSequence?.supplyList}
          supplyRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              onClick: () => {
                let result = {
                  ...modSetSupply.data,
                  supplySelected: props.AnesTemplateSequence?.supplyList[rowInfo?.index],
                };
                setModSetSupply({
                  ...modSetSupply,
                  showSearchResult: false,
                  data: result,
                });
              },
            };
          }}
          onSupplySearchTextChange={(e: any) => {
            let result = {
              ...modSetSupply.data,
              supplySearch: e.target.value,
              supplySelected: {},
            };
            setModSetSupply({ ...modSetSupply, data: result });

            // props.runSequence({
            //   sequence: "AnesTemplate",
            //   action: "SEARCH_SUPPLY",
            //   keyword: e.target.value,
            // });
          }}
          onClickSupplySearch={() => {
            if (modSetSupply.data?.supplySelected?.name) {
              let result = {
                ...modSetSupply.data,
                supplySearch: "",
                supplySelected: {},
              };
              setModSetSupply({ ...modSetSupply, data: result });
            } else {
              props.runSequence({
                sequence: "AnesTemplate",
                action: "SEARCH_SUPPLY",
                keyword: modSetSupply.data?.supplySearch,
              });
            }
          }}
          onClearSupplySearch={() => {
            let result = {
              ...modSetSupply.data,
              supplySearch: "",
              supplySelected: {},
            };
            setModSetSupply({ ...modSetSupply, data: result });
          }}
          // component
          buttonCreateSupply={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "AnesTemplate",
                  action: "SAVE_SUPPLY_ANS_ITEM",
                  cardKey: CARD_KEY,
                  data: {
                    id: modSetSupply.data?.id || false,
                    eligibility_type: modSetSupply.data?.eligibilityType,
                    supply: modSetSupply.data?.supplySelected?.id,
                    remark: modSetSupply.data?.remark,
                  },
                });
              }}
              // data
              paramKey={`${CARD_KEY}_SAVE_SUPPLY_ANS_ITEM`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_SAVE_SUPPLY_ANS_ITEM`]}
              // config
              color="green"
              size="medium"
              fluid={true}
              title={intl.formatMessage({ id: "บันทึกการแก้ไข" })}
            />
          }
          languageUX={props.languageUX}
        />
      </Modal>

      {/* Setup Treatment */}
      <Modal
        closeIcon
        size="large"
        open={modSetTreatment.open && props.AnesTemplateSequence?.templateSelected?.id}
        onClose={handleCloseModSetTreatment}
      >
        <CardSetupAnesthesiaTemplateTreatmentUX
          // function
          onChangeTreatmentANS={handleChangeTreatmentANS}
          // options
          masterOptions={props.masterOptions}
          performerOptions={[
            { key: 1, value: "DOCTOR", text: "Doctor" },
            { key: 2, value: "NURSE", text: "Nurse" },
            { key: 3, value: "OTHER", text: "Other" },
          ]}
          // data
          template={props.AnesTemplateSequence?.templateSelected}
          data={modSetTreatment.data}
          dataICD9={modSetTreatment.dataICD9}
          onCancelEdit={handleCloseModSetTreatment}
          // table
          showSearchResult={modSetTreatment.showSearchResult}
          showSearchICD9={modSetTreatment.showSearchICD9}
          treatmentSearchResult={props.AnesTemplateSequence?.treatmentList}
          treatmentRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              onClick: () => {
                let result = {
                  ...modSetTreatment.data,
                  treatmentSelected: props.AnesTemplateSequence?.treatmentList[rowInfo?.index],
                };
                setModSetTreatment({
                  ...modSetTreatment,
                  showSearchResult: false,
                  data: result,
                });
              },
            };
          }}
          onTreatmentSearchTextChange={(e: any) => {
            let result = {
              ...modSetTreatment.data,
              treatmentSearch: e.target.value,
              treatmentSelected: {},
            };
            setModSetTreatment({ ...modSetTreatment, data: result });

            props.runSequence({
              sequence: "AnesTemplate",
              action: "SEARCH_TREATMENT",
              keyword: e.target.value,
            });
          }}
          onClearTreatmentSearch={() => {
            let result = {
              ...modSetTreatment.data,
              treatmentSearch: "",
              treatmentSelected: {},
            };
            setModSetTreatment({ ...modSetTreatment, data: result });
          }}
          onICD9SearchTextChange={(e: any) => {
            let result = {
              treatmentSearch: e.target.value,
              treatmentSelected: {},
            };
            setModSetTreatment({ ...modSetTreatment, dataICD9: result });

            props.runSequence({
              sequence: "AnesTemplate",
              action: "SEARCH_ICD9",
              keyword: e.target.value,
            });
          }}
          icd9SearchResult={props.AnesTemplateSequence?.icd9List}
          icd9RowProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              onClick: () => {
                let result = {
                  ...modSetTreatment.data,
                  treatmentSelected: props.AnesTemplateSequence?.icd9List[rowInfo?.index],
                };
                setModSetTreatment({
                  ...modSetTreatment,
                  showSearchICD9: false,
                  dataICD9: result,
                });
              },
            };
          }}
          // component
          buttonCreateTreatment={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "AnesTemplate",
                  action: "SAVE_TREATMENT_ANS_ITEM",
                  cardKey: CARD_KEY,
                  data: {
                    id: modSetTreatment.data?.id || false,
                    // icd9cm: modSetTreatment.dataICD9?.treatmentSelected?.icdterm,
                    icd9cm: modSetTreatment.dataICD9?.treatmentSelected?.icd10_id,
                    icd9cm_med_term:
                      modSetTreatment.dataICD9?.treatmentSelected?.medterm_id || null,
                    to_be_performer: modSetTreatment.data?.performer,
                    order_detail: modSetTreatment.data?.orderDetail,
                    equipment_detail: modSetTreatment.data?.equipmentDetail,
                    eligibility_type: modSetTreatment.data?.eligibilityType,
                    treatment: modSetTreatment.data?.treatmentSelected?.id,
                    remark: modSetTreatment.data?.remark,
                  },
                });
              }}
              // data
              paramKey={`${CARD_KEY}_SAVE_TREATMENT_ANS_ITEM`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_SAVE_TREATMENT_ANS_ITEM`]}
              // config
              color="green"
              size="medium"
              fluid={true}
              title={intl.formatMessage({ id: "บันทึก" })}
            />
          }
          getICD9={() => {
            props.runSequence({
              sequence: "AnesTemplate",
              action: "GET_ICD9",
            });
          }}
          removeICDterm={() => {
            setModSetTreatment({
              ...modSetTreatment,
              showSearchICD9: false,
              dataICD9: {},
            });
          }}
          languageUX={props.languageUX}
        />
      </Modal>

      {/* Delete Template */}
      <ModConfirm
        openModal={openModDeleteTemplate}
        titleName={intl.formatMessage({ id: "กรุณายืนยัน" })}
        content={`ต้องการลบ Template ${props.AnesTemplateSequence?.templateSelected?.name}
            ใช่หรือไม่`}
        onApprove={handleDeleteTemplate}
        onDeny={handleCloseModDeleteTemplate}
      />

      {/* Delete Drug */}
      <ModConfirm
        openModal={modDeleteDrug.open}
        titleName={intl.formatMessage({ id: "กรุณายืนยัน" })}
        content={`ต้องการลบ Drug ${modDeleteDrug.item?.product_full_name || ""}
            ใช่หรือไม่`}
        onApprove={handleDeleteDrug}
        onDeny={handleCloseModDeleteDrug}
      />

      {/* Delete Supply */}
      <ModConfirm
        openModal={modDeleteSupply.open}
        titleName={intl.formatMessage({ id: "กรุณายืนยัน" })}
        content={`ต้องการลบ Supply ${modDeleteSupply.item?.name || ""}
            ใช่หรือไม่`}
        onApprove={handleDeleteSupply}
        onDeny={handleCloseModDeleteSupply}
      />

      {/* Delete Treatment */}
      <ModConfirm
        openModal={modDeleteTreatment.open}
        titleName={intl.formatMessage({ id: "กรุณายืนยัน" })}
        content={`ต้องการลบ Treatment ${modDeleteTreatment.item?.name || ""}
            ใช่หรือไม่`}
        onApprove={handleDeleteTreatment}
        onDeny={handleCloseModDeleteTreatment}
      />
    </>
  );
};

export default React.memo(CardSetupAnesthesiaTemplate);
